import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";

import LandingPage from "./pages/LandingPage/LandingPage";
import OurStoryPage from "./pages/OurStoryPage/OurStoryPage";
import TheProblemPage from "./pages/TheProblemPage/TheProblemPage";
import PricingPage from "./pages/PricingPage/PricingPage";
import FaqPage from "./pages/FaqPage/FaqPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/our-story" element={<OurStoryPage />} />
        <Route path="/the-problem" element={<TheProblemPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        {/* <Route path="/gallery" element={<GalleryPage />} /> */}
        {/* <Route path="/careers" element={<CareersPage />} /> */}
        <Route path="/faq" element={<FaqPage />} />
      </Routes>
    </Router>
  );
}

export default App;
