import "./HeroVideo.scss";
import heroVideo from "../../../videos/hero-video.mp4";

function HeroVideo() {
  
	return (
		<div className="video-container">
			<video src={heroVideo} autoPlay loop muted playsInline/>
			<div className="container">
				<div className="text-overlay">
					<p className="header-text">Be One to Give</p>
					<p className="sub-text">on-demand delivery app for surplus food!</p>
				</div>
			</div>
		</div>
	);
}

export default HeroVideo;