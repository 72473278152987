import React, { useEffect, useState } from "react";
import { fetchTotalImpactData } from "../../../api/totalImpactApi";
import "./OurImpact.scss";

import impactTony from "../../../images/impact-image-tony.png";

function OurImpact() {
  const [totalImpactData, setTotalImpactData] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchTotalImpactData();
        const formattedData = {};
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            formattedData[key] = formatNumber(data[key]);
          }
        }
        setTotalImpactData(formattedData);
      } catch (error) {
        console.error("Error fetching total impact data:", error);
      }
    }

    fetchData();
  }, []);

  const formatNumber = (value) => {
    const roundedValue = Math.round(value / 1000) * 1000;
    const firstTwoDigits = Math.floor(roundedValue / 1000);

    return `${firstTwoDigits}k +`;
  };
  return (
    <div className="container impact-container">
      <img
        src={impactTony}
        alt="Tony (CEO) pulling food bin"
        className="impact-image"
      />
      <div className="impact-wrapper">
        <h2 className="section-title-orange">Impact</h2>
        <div className="impact-stats">
          <p className="impact-number">{totalImpactData.poundsCollected}</p>
          <p className="impact-type">Pounds of food redistributed</p>
          <p className="impact-number">{totalImpactData.peopleFed}</p>
          <p className="impact-type">Numbers of people fed</p>
          <p className="impact-number">
            {totalImpactData.methaneEmissionsDiverted}
          </p>
          <p className="impact-type">Pounds of methane gas diverted</p>
        </div>
      </div>
    </div>
  );
}

export default OurImpact;
