import React from "react";
import sliderSettings from "../../../components/sliderSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FeaturedOn.scss";

import cbc from "../../../images/featured-on/cbc.png";
import cityNews from "../../../images/featured-on/city-news.png";
import ctv from "../../../images/featured-on/ctv.png";
import global from "../../../images/featured-on/global.png";
import goodNews from "../../../images/featured-on/good-news.png";
import theGlobe from "../../../images/featured-on/the-globe.png";

function FeaturedOn() {
  const logos = [
    {
      link: "https://www.cbc.ca/news/canada/toronto/surplus-food-delivery-app-1.6912325?fbclid=PAAab1ZBKcO-50LnEm_0q5UvpyGHiWeD01FvMdEsUxPrPWHOJH2niQy60fP7A_aem_AY9eVJFsxA37gc5tHqVyPPWovWyjRBjGTGkCzSxzOlVBBq7yVzxBg7r1f0ndFe3iFSw",
      image: cbc,
    },
    {
      link: "https://toronto.citynews.ca/video/2020/01/20/tackling-ontarios-food-waste-crisis/",
      image: cityNews,
    },
    { link: "https://www.ctvnews.ca/video?clipId=1917083", image: ctv },
    {
      link: "https://images.squarespace-cdn.com/content/v1/5c2edb7a5417fc1b3d2ffc15/1669950458406-JAEHG58G0GKMO3M6FYXO/global.png?format=500w",
      image: global,
    },
    {
      link: "https://www.goodnewsnetwork.org/man-launches-uber-eats-style-food-rescue-program/",
      image: goodNews,
    },
    {
      link: "https://www.theglobeandmail.com/business/adv/article-diverting-food-waste-feeding-families/",
      image: theGlobe,
    },
  ];

  return (
    <div className="slider-section-container">
      <h2 className="section-title-orange">Featured On</h2>
      <Slider {...sliderSettings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-container">
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <img
                src={logo.image}
                alt="Featured press logo"
                className="slider-logo"
              />
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default FeaturedOn;
