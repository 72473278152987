import "./GetInvolved.scss";
import retailerApp from "../../../images/retailer-preview.png";
import driverApp from "../../../images/driver-preview.png";
import communityApp from "../../../images/shelter-preview.png";

function GetInvolved() {
  return (
    <div className=" container get-involved-section">
      <h2 className="section-title-orange">Get Involved</h2>
      <p className="section-text">
        Join our movement to combat food waste and food insecurity across
        Canada.
      </p>
      <div className="app-list-container">
        <div className="app-wrapper">
          <h3 className="app-title">Retailer</h3>
          <img
            src={retailerApp}
            alt="Retailer app mockup"
            className="app-mockup"
          />
          <a
            href="https://linktr.ee/b12give"
            target="_blank"
            className="primary-button"
            rel="noopener noreferrer"
          >
            Download now
          </a>
        </div>
        <div className="app-wrapper">
          <h3 className="app-title">Driver / Co-pilot</h3>
          <img
            src={driverApp}
            alt="Driver/Co-pilot app mockup"
            className="app-mockup"
          />
          <a
            href="https://linktr.ee/b12give"
            target="_blank"
            rel="noopener noreferrer"
            className="primary-button"
          >
            Download now
          </a>
          {/* <Link to="/careers" className="primary-button">
            Apply Now
          </Link> */}
        </div>
        <div className="app-wrapper">
          <h3 className="app-title">Community</h3>
          <img
            src={communityApp}
            alt="Community app mockup"
            className="app-mockup"
          />
          <a
            href="https://linktr.ee/b12give"
            target="_blank"
            rel="noopener noreferrer"
            className="primary-button"
          >
            Download now
          </a>
        </div>
      </div>
    </div>
  );
}

export default GetInvolved;
