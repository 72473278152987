import "../SupportersGoals/SupportersGoals.scss";

import sliderSettings from "../../../components/sliderSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import dmz from "../../../images/pricing/dmz.png";
import innovationGuelph from "../../../images/pricing/innovation-guelph.png";
import coil from "../../../images/pricing/coil.png";
import spring from "../../../images/pricing/spring.png";
import loiLabs from "../../../images/pricing/loi-labs.png";
import raiseGrants from "../../../images/pricing/raise-grants.png";
import communitech from "../../../images/pricing/communitech.png";
import startupCanada from "../../../images/pricing/startup-canada.png";

import zeroHunger from "../../../images/pricing/zero-hunger.png";
import workGrowth from "../../../images/pricing/work-growth.png";
import reduceInequality from "../../../images/pricing/reduce-inequality.png";
import climateAction from "../../../images/pricing/climate-action.png";

function SupportersGoals() {
	const supportersLogo = [
		dmz,
		innovationGuelph,
		coil,
		loiLabs,
		spring,
		raiseGrants,
		communitech,
		startupCanada,
	];

	const goalsLogos = [zeroHunger, workGrowth, reduceInequality, climateAction];

	return (
		<div className="supporters-goals">
			<div className="slider-section-container">
				<h2 className="section-title-orange">Supported By</h2>
				<Slider {...sliderSettings}>
					{supportersLogo.map((logo, index) => (
						<div
							key={index}
							className="logo-container"
						>
							<img
								src={logo}
								alt="Supporters logo"
								className="slider-logo"
							/>
						</div>
					))}
				</Slider>
			</div>
			<div className="goals-section">
                <h2 className="section-title-orange">Sustainable Development Goals</h2>
                <div className="goals-logo-wrapper">
                    {goalsLogos.map((logo, index) => (
                        <div
                            key={index}
                            className="goals-logo"
                        >
                            <img
                                src={logo}
                                alt="Sustainable Development Goals logo"
                                className="goals-logo"
                            />
                        </div>
                    ))}
                </div>
			</div>
		</div>
	);
}

export default SupportersGoals;
