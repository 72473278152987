import Layout from "../../components/Layout";
import FeaturedOn from "./FeaturedOn/FeaturedOn";
import GetInvolved from "./GetInvolved/GetInvolved";
import HeroVideo from "./HeroVideo/HeroVideo";
import HowItWorks from "./HowItWorks/HowItWorks";
import MissionStatements from "./MissionStatements/MissionStatements";
import OurClients from "./OurClients/OurClients";
import OurImpact from "./OurImpact/OurImpact";
import OurRecipients from "./OurRecipients/OurRecipients";
import Testimonial from "./Testimonial/Testimonial";
import "./LandingPage.scss";

function LandingPage() {
  return (
    <Layout>
      <HeroVideo />
      <MissionStatements />
      <HowItWorks />
      <FeaturedOn />
      <hr className="landing-lines" />
      <OurImpact />
      <hr className="landing-lines" />
      <OurClients />
      <OurRecipients />
      <Testimonial />
      <GetInvolved />
    </Layout>
  );
}

export default LandingPage;
