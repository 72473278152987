import { Link } from "react-router-dom";
import "./HowItWorks.scss";

import appStoreBadge from "../../../images/app-store-badge.png";
import googlePlayBadge from "../../../images/google-play-badge.png";
import bCorp from "../../../images/b-corp-logo.png";

import appDemo from "../../../videos/demo-video.mp4";

function HowItWorks() {
  return (
    <div className="section-container how-it-works">
      <video
        src={appDemo}
        autoPlay
        loop
        muted
        playsInline
        controls
        className="app-demo-video"
      />
      <div className="section-wrapper">
        <h2 className="section-header">How it works?</h2>
        <p className="section-text">
          We operate like Uber Eats for surplus prepared food.
        </p>
        <ol>
          <li>
            <strong>Step 1:</strong> Download the “Retailer” app and create a
            profile.
          </li>
          <li>
            <strong>Step 2:</strong> Meet with our team to complete onboard
            training.
          </li>
          <li>
            <strong>Step 3:</strong> Place an order and a driver will be
            dispatched.
          </li>
          <li>
            <strong>Step 4:</strong> Track your impact live with each order!
          </li>
        </ol>
        <p>
          Our circular technology <strong>guarantees</strong> to capture and
          deliver 100% of surplus food from your daily operations to food
          insecure communities within 2 hours of receipt.
        </p>
        <div className="how-it-works-text">
          <div className="app-download-container">
            <a
              href="https://linktr.ee/b12give"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={appStoreBadge}
                alt="App Store Badge"
                className="app-download-badge"
              />
            </a>
            <a
              href="https://linktr.ee/b12give"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={googlePlayBadge}
                alt="Google Play Badge"
                className="app-download-badge"
              />
            </a>
          </div>
          <Link to="/pricing" className="secondary-button">
            Learn more
          </Link>
        </div>
      </div>
      <img src={bCorp} alt="B Corp Logo" className="b-corp-logo" />
    </div>
  );
}

export default HowItWorks;
