import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchTotalImpactData = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/users/reports/totalImpact`);
      return response.data;
  } catch (error) {
        throw error;
    }
};
