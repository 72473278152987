import Layout from "../../components/Layout";
import Banner from "../../components/Banner/Banner";

import bannerImage from "../../images/app-screens-banner.jpeg";
import bgImage from "../../images/faq-doodles.png";

import "../FaqPage/FaqPage.scss";

function FaqPage() {
  return (
    <Layout>
      <Banner image={bannerImage} />
      <div className="container faq-container">
        <img
          src={bgImage}
          className="background-centre"
          alt="Food Doodles Graphic Design"
        />
        <h2 className="section-title-grey">FAQ</h2>
        <h3 className="section-title-orange">What is the B12Give app?</h3>
        <p>
          We are a surplus food recovery app for business along the supply
          chain. We're like “Instacart” for surplus food.
        </p>
        <h3 className="section-title-orange">
          Are you a non-profit or charitable organization?
        </h3>
        <p>
          No. In order to be a non-profit or charitable organization we must
          provide social programming beyond food delivery. We are a for-profit
          social enterprise operating similar to other food delivery apps with a
          social purpose of feeding those who are food insecure.
        </p>
        <h3 className="section-title-orange">
          How does your app reduce avoidable food waste?
        </h3>
        <p>
          We capture 100% of customers' surplus food waste whenever they place
          an order.
        </p>
        <h3 className="section-title-orange">
          What makes your service unique?
        </h3>
        <ul>
          <li>
            ✔️ We are the only app in Canada to provide logistics for surplus
            food recovery.
          </li>
          <li>
            ✔️ Our service is barrier free so we provide greater direct access
            to surplus food.
          </li>
          <li>
            ✔️ We add value to all other food waste reduction programs by
            capturing any surplus food remaining onsite.
          </li>
          <li>✔️ We deliver food within 2-hours of receipt to our end user.</li>
        </ul>
        <h3 className="section-title-orange">
          Are there any restrictions on the type of food you collect?
        </h3>
        <p>
          We target surplus 'prepared' food with a shelf-life of less than 48
          hours from 1 pound and up; however we can accommodate other food
          groups as well.
        </p>
        <h3 className="section-title-orange">
          Are there any legal risks associated with donating food?
        </h3>
        <p>
          The Ontario Donation of Food Act assures no person or distributor is
          held liable if someone gets sick from the consumption of donated food.
        </p>
      </div>
    </Layout>
  );
}

export default FaqPage;
