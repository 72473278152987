import { useState } from "react";

import Banner from "../../components/Banner/Banner";
import Layout from "../../components/Layout";
import "../OurStoryPage/OurStoryPage.scss";
import teamData from "./teamData";

import bannerImage from "../../images/our-story/our-story-banner.jpg";
import cleanBadge from "../../images/our-story/clean-50-badge.png";
import telusBadge from "../../images/our-story/telus-badge.png";
import tonyProfilePicture from "../../images/our-story/team-pictures/ceo.jpeg";

const paragraphStyles = {
	WebkitLineClamp: 6,
	WebkitBoxOrient: "vertical",
	overflow: "hidden",
	display: "-webkit-box",
};

function OurStoryPage() {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleContent = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<Layout>
			<div className="our-story-page">
				<Banner image={bannerImage} />
				<div className="container our-story-container">
					<div className="about-us-section">
						<h2 className="section-title-grey">About Us</h2>
						<iframe
							className="youtube-video"
							src="https://www.youtube.com/embed/_U2uRF80cXk?si=FUnfmVcTpL9MHx6V&modestbranding=1"
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						></iframe>
						<div className="about-us-badge">
							<a
								href="https://clean50.com/projects/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={cleanBadge}
									alt="Clean 50 Top Project Badge"
									className="feature-badge"
								/>
							</a>
							<a
								href="https://futureofgood.co/new-founders-to-watch-2022/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={telusBadge}
									alt="Telus Pollinator Fund for Good Badge"
									className="feature-badge"
								/>
							</a>
						</div>
					</div>
					<h2 className="section-title-grey">Our Leadership Team</h2>
					<div className="leadership-section">
						<div className="profile-card">
							<div className="picture-wrapper">
								<img
									src={tonyProfilePicture}
									className="team-member-picture"
									alt="Tony Colley CEO Profile"
								/>
							</div>
							<div className="team-member-info">
								<p className="team-member-name">Tony Colley</p>
								<p className="team-member-role">CEO / Founder</p>
							</div>
						</div>
						<h2 className="section-title-orange">His Story</h2>
						<div className={`story-container ${isExpanded ? "expanded" : ""}`}>
							<div style={isExpanded ? null : paragraphStyles}>
								<p>
									In 2016, I found myself on social assistance and food insecure
									after a 23 year career in corporate finance, fundraising and
									event production.
								</p>
								<p>
									The only job I could find was part-time for a local caterer
									and on my first event we had over 100 boxed lunches leftover.
									Being food insecure, I took a few home for myself and donated
									the surplus to a nearby shelter.
								</p>
								<p>
									After more than a year of donations I decided to purchase a
									food delivery bag to make the process easier and immediately
									saw an opportunity to build a more circular solution to
									surplus food recovery.
								</p>
								<p>
									I spent 2018 researching food waste/food insecurity and
									current recovery solutions along the food supply chain, and
									noticed an obvious gap in the market for technology that could
									tackle both issues simultaneously.
								</p>
								<p>
									We launched the pilot Q4 2019 and by Q1 2020 had received
									national recognition for having redistributed more than{" "}
									<b>12,000 lbs</b> of food feeding roughly <b>9,100 people</b>.
								</p>
								<p>
									The pandemic temporarily halted our progress, however
									amplified the issue of food insecurity and the need for a more
									sustainable ‘circular’ solution to lost or wasted surplus
									food.
								</p>
								<p>
									<b>#B12GIVE</b>
								</p>
							</div>
							<button
								onClick={toggleContent}
								className="read-button"
							>
								{isExpanded ? "Read Less" : "Read More"}
							</button>
						</div>
					</div>
					<h2 className="section-title-grey">Our Staff</h2>
					<div className="team-section">
						{teamData
							.filter((employee) => employee.category === "Staff")
							.map((employee) => (
								<div
									className="profile-card"
									key={employee.fullName}
								>
									<div className="picture-wrapper">
										<img
											src={employee.profilePicture}
											className="team-member-picture"
											alt="Team Member Profile"
										/>
									</div>
									<div className="team-member-info">
										<p className="team-member-name">{employee.fullName}</p>
										<p className="team-member-role">{employee.roleTitle}</p>
									</div>
								</div>
							))}
					</div>
					<h2 className="section-title-grey">Our Advisors</h2>
					<div className="team-section">
						{teamData
							.filter((employee) => employee.category === "Advisor")
							.map((employee) => (
								<div
									className="profile-card"
									key={employee.fullName}
								>
									<div className="picture-wrapper">
										<img
											src={employee.profilePicture}
											className="team-member-picture"
											alt="Team Member Profile"
										/>
									</div>
									<div className="team-member-info">
										<p className="team-member-name">{employee.fullName}</p>
										<p className="team-member-role">{employee.roleTitle}</p>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default OurStoryPage;
