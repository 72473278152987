import { Link } from "react-router-dom";
import "../Footer/Footer.scss";
import logoWhite from "../../images/logo-white.png";
import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoLinkedin,
} from "react-icons/bi";

function Footer() {
  return (
    <div className="footer-container">
      <div className="company-info-wrapper">
        <img
          src={logoWhite}
          alt="B12GIVE logo in white"
          className="footer-logo"
        />
        <div className="social-media-icons-wrapper">
          <a
            href="http://instagram.com/b12give"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <BiLogoInstagram className="social-media-icon" />
          </a>
          <a
            href="https://www.facebook.com/B12Give/?modal=admin_todo_tour"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <BiLogoFacebook className="social-media-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/be-one-to-give/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
            className="icon-link"
          >
            <BiLogoLinkedin className="social-media-icon" />
          </a>
        </div>
      </div>
      <div className="footer-menu">
        <div className="link-categories">
          <Link to="/our-story" className="link">
            Our Story
          </Link>
          <Link to="/the-problem" className="link">
            The Problem
          </Link>
          <Link to="/pricing" className="link">
            Pricing
          </Link>
          {/* <Link to="/gallery" className="link">
            Gallery
          </Link> */}
          {/* <Link to="/careers" className="link">
            Careers
          </Link> */}
          <a href="mailto:support@b12give.com" className="link">
            Contact
          </a>
          <Link to="/faq" className="link">
            FAQ
          </Link>
        </div>
        <div className="footer-contact-info">
          <a href="mailto:support@b12give.ca" className="contact">
            E: support@b12give.ca
          </a>
          <a href="tel:+16472124483" className="contact">
            T: (647) B12-GIVE (4483)
          </a>
        </div>
        <div className="hours-operation">
          <p>Daily from 3pm</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
