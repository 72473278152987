import { Link } from "react-router-dom";
import { Flat } from "@alptugidin/react-circular-progress-bar";
import Layout from "../../components/Layout";
import Banner from "../../components/Banner/Banner";

import "../TheProblemPage/TheProblemPage.scss";

import bannerImage from "../../images/the-problem/the-problem-banner.jpg";
import bgLeft from "../../images/the-problem/food-doodles-left.png";
import bgRight from "../../images/the-problem/food-doodles-right.png";

function TheProblemPage() {
  return (
    <Layout>
      <Banner image={bannerImage} />
      <img
        src={bgLeft}
        className="background-left"
        alt="Food Doodles Graphic Design"
      />
      <img
        src={bgRight}
        className="background-right"
        alt="Food Doodles Graphic Design "
      />
      <div className="container the-problem-container">
        <h1 className="section-title-green">The Facts Behind Food Waste</h1>
        <div className="food-waste-stats-wrapper">
          <div className="food-waste-stats">
            <h2 className="section-title-orange">Costly Problem</h2>
            <p>
              Canada has a{" "}
              <a
                href="https://secondharvest.ca/resources/research"
                target="_blank"
                rel="noopener noreferrer"
                className="stats-link"
              >
                $49B
              </a>{" "}
              food waste issue which generates 56.5 million tonnes of methane
              gas.
            </p>
          </div>
          <div className="food-waste-stats">
            <h2 className="section-title-orange">Food Insecurity</h2>
            <p>
              <a
                href="https://www150.statcan.gc.ca/n1/pub/45-28-0001/2020001/article/00039-eng.htm"
                target="_blank"
                rel="noopener noreferrer"
                className="stats-link"
              >
                1 in 7
              </a>{" "}
              Canadians are now food insecure, struggling to put food on the
              table.
            </p>
          </div>
          <div className="food-waste-stats">
            <h2 className="section-title-orange">Food Bank Surge</h2>
            <p>
              <a
                href="https://www.dailybread.ca/research-and-advocacy/whos-hungry-report-2021/"
                target="_blank"
                rel="noopener noreferrer"
                className="stats-link"
              >
                2.5 million
              </a>{" "}
              users accessed Toronto food banks last year; highest ever on
              record.
            </p>
          </div>
          <div className="food-waste-stats">
            <h2 className="section-title-orange">Racial Disparity</h2>
            <p>
              Black households are{" "}
              <a
                href="https://www.broadbentinstitute.ca/black_food_insecurity_in_canada"
                target="_blank"
                rel="noopener noreferrer"
                className="stats-link"
              >
                3.5x
              </a>{" "}
              more likely to be food insecure than their white counterparts.
            </p>
          </div>
        </div>
        <div className="circular-stats-section">
          <div className="circular-stats-wrapper">
            <a
              href="https://secondharvest.ca/resources/research/the-avoidable-crisis-of-food-waste"
              target="_blank"
              rel="noopener noreferrer"
              className="circular-stat-links"
            >
              <p className="circular-stats-text">
                <span className="stats-link">58%</span> of all food produced in
                Canada is lost or wasted each year!
              </p>
              <Flat
                progress={58}
                range={{ from: 0, to: 100 }}
                showMiniCircle={false}
                showValue={false}
                sx={{
                  strokeColor: "#E37263",
                  barWidth: 4,
                  bgStrokeColor: "#F1F1F1",
                  bgColor: { value: "#FFFFFF", transparency: "99" },
                  shape: "full",
                  strokeLinecap: "round",
                  loadingTime: 1000,
                  valueAnimation: true,
                  intersectionEnabled: false,
                }}
              />
            </a>
          </div>
          <div className="circular-stats-wrapper">
            <a
              href="https://secondharvest.ca/resources/research/the-avoidable-crisis-of-food-waste"
              target="_blank"
              rel="noopener noreferrer"
              className="circular-stat-links"
            >
              <p className="circular-stats-text">
                <span className="stats-link">79%</span> of that waste is
                happening in businesses along the supply chain!
              </p>
              <Flat
                progress={79}
                range={{ from: 0, to: 100 }}
                showMiniCircle={false}
                showValue={false}
                sx={{
                  strokeColor: "#E37263",
                  barWidth: 4,
                  bgStrokeColor: "#F1F1F1",
                  shape: "full",
                  strokeLinecap: "round",
                  loadingTime: 1000,
                  valueAnimation: true,
                  intersectionEnabled: false,
                }}
              />
            </a>
          </div>
        </div>
        <div className="canada-stats-section">
          <div className="canada-stats-wrapper">
            <p>
              Canada's surplus edible food production amounts to{" "}
              <a
                href="https://www.secondharvest.ca/resources/research/wasted-opportunity"
                target="_blank"
                rel="noopener noreferrer"
                className="stats-link"
              >
                3.2M
              </a>{" "}
              metric tonnes every year.
            </p>
            <p>
              A startling{" "}
              <a
                href="https://www.secondharvest.ca/resources/research/wasted-opportunity"
                target="_blank"
                rel="noopener noreferrer"
                className="stats-link"
              >
                96%
              </a>{" "}
              of surplus food is lost along Canada's value chain without being
              rescued or redistributed.
            </p>
          </div>
        </div>
        <hr />
        <div className="global-waste-section">
          <h1 className="section-title-green">Curious About Food Waste?</h1>
          <p>
            According to the{" "}
            <a
              href="https://www.fao.org/home/en/"
              target="_blank"
              rel="noopener noreferrer"
              className="stats-link"
            >
              U.N. Food and Agriculture Organization
            </a>
            , it is estimated that every pound of food thrown away results in
            3.8 pounds of methane gas. This greenhouse gas has a global warming
            potential of{" "}
            <a
              href="https://secondharvest.ca/resources/research/the-avoidable-crisis-of-food-waste"
              target="_blank"
              rel="noopener noreferrer"
              className="stats-link"
            >
              25x
            </a>{" "}
            that of carbon dioxide and is{" "}
            <a
              href="https://unece.org/challenge"
              target="_blank"
              rel="noopener noreferrer"
              className="stats-link"
            >
              84x
            </a>{" "}
            more potent in the first 20 years.
          </p>
          <h2 className="section-title-orange">What is the global impact?</h2>
          <p>
            <a
              href="https://www.fao.org/home/en/"
              target="_blank"
              rel="noopener noreferrer"
              className="stats-link"
            >
              8%
            </a>{" "}
            of global greenhouse gas emissions stems from human caused food
            waste.
          </p>
          <p>
            Global food waste is the{" "}
            <a
              href="https://www.unenvironment.org/regions/north-america/regional-initiatives/minimizing-food-waste"
              target="_blank"
              rel="noopener noreferrer"
              className="stats-link"
            >
              3rd largest
            </a>{" "}
            greenhouse gas emitter behind China and the USA.
          </p>
          <p>
            Reducing food waste has the potential to draw{" "}
            <a
              href="https://www.waste360.com/food-waste/whats-most-important-solution-climate-change-reduce-food-waste"
              target="_blank"
              rel="noopener noreferrer"
              className="stats-link"
            >
              87 gigatons
            </a>{" "}
            of greenhouse gas out of the atmosphere.
          </p>
        </div>
        <div className="battle-waste-section">
          <p className="section-title-green">
            Ready to battle food waste and change the future?
            <br />
            Join us now.
          </p>
          <div className="contact-learn-buttons">
            <a className="primary-button" href="mailto:your-email@example.com">
              Contact Us
            </a>
            <Link to="/pricing" className="secondary-button">
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TheProblemPage;
