import advisor1 from "../../images/our-story/team-pictures/advisor1.jpeg";
import advisor2 from "../../images/our-story/team-pictures/advisor2.jpeg";
import advisor3 from "../../images/our-story/team-pictures/advisor3.jpeg";

import staff1 from "../../images/our-story/team-pictures/staff1.jpeg";
import staff2 from "../../images/our-story/team-pictures/staff2.jpeg";
import staff3 from "../../images/our-story/team-pictures/staff3.jpeg";

const teamData = [
	{
		profilePicture: staff1,
		fullName: "Onimisi Ukanah",
		roleTitle: "Lead Developer",
		category: "Staff",
	},
	{
		profilePicture: staff2,
		fullName: "Sam Oue",
		roleTitle: "Social Media Manager",
		category: "Staff",
	},
	{
		profilePicture: staff3,
		fullName: "Katie Liu",
		roleTitle: "Junior Developer Intern",
		category: "Staff",
	},
	{
		profilePicture: advisor1,
		fullName: "Janey Buzugbe",
		roleTitle: "Advisor",
		category: "Advisor",
	},
	{
		profilePicture: advisor2,
		fullName: "Annette Evans",
		roleTitle: "Advisor",
		category: "Advisor",
	},
	{
		profilePicture: advisor3,
		fullName: "Arthur Stanley",
		roleTitle: "Advisor",
		category: "Advisor",
	},
];

export default teamData;
