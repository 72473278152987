import React from "react";
import sliderSettings from "../../../components/sliderSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./OurRecipients.scss";

import afriCan from "../../../images/our-recipients/afri-can.jpeg";
import dixonHall from "../../../images/our-recipients/dixon-hall.jpeg";
import fredVictor from "../../../images/our-recipients/fred-victor.jpeg";
import goodShepherd from "../../../images/our-recipients/good-shepherd.jpeg";
import homesFirst from "../../../images/our-recipients/homes-first.jpeg";
import kerrStreet from "../../../images/our-recipients/kerr-street.jpeg";
import margarets from "../../../images/our-recipients/margarets.jpeg";
import stFelix from "../../../images/our-recipients/st-felix.jpeg";
import stFrancis from "../../../images/our-recipients/st-francis.jpeg";
import eva from "../../../images/our-recipients/eva-logo.png";

function OurRecipients() {
  const logos = [
    afriCan,
    dixonHall,
    fredVictor,
    goodShepherd,
    homesFirst,
    kerrStreet,
    margarets,
    stFelix,
    stFrancis,
    eva,
  ];

  return (
    <div className="slider-section-container">
      <h2 className="section-title-orange">Recipients</h2>
      <Slider {...sliderSettings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-container">
            <img src={logo} alt="Recipient logo" className="slider-logo" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OurRecipients;
