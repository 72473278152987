import React from "react";
import sliderSettings from "../../../components/sliderSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./OurClients.scss";

import citco from "../../../images/our-clients/citco.png";
import fairmont from "../../../images/our-clients/fairmont.png";
import oliverBonacini from "../../../images/our-clients/oliver-bonacini.png";
import sheridan from "../../../images/our-clients/sheridan-logo.png";
import tmu from "../../../images/our-clients/tmu.png";
import shopify from "../../../images/our-clients/shopify.png";
import pwc from "../../../images/our-clients/pwc.png";
import arcadian from "../../../images/our-clients/arcadian.png";
import torontoRegion from "../../../images/our-clients/toronto-board-of-trade.png";
import carlu from "../../../images/our-clients/carlu.png";
import malaparte from "../../../images/our-clients/malaparte.png";
import havergal from "../../../images/our-clients/havergal-college.png";

function OurClients() {
  const logos = [
    citco,
    fairmont,
    oliverBonacini,
    sheridan,
    tmu,
    shopify,
    pwc,
    arcadian,
    torontoRegion,
    carlu,
    malaparte,
    havergal,
  ];

  return (
    <div className="slider-section-container">
      <h2 className="section-title-orange">Partners</h2>
      <Slider {...sliderSettings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-container">
            <img src={logo} alt="Client logo" className="slider-logo" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default OurClients;
