import "./Testimonial.scss";

import sheridanLogo from "../../../images/our-clients/sheridan.png";
import arrow from "../../../images/arrow-bent.png";

function Testimonial() {
  return (
    <div className="testimonial-section">
      <h2 className="section-header">What Our Clients Are Saying</h2>

      <div className="testimonial-text-wrapper">
        <p className="testimonial-text">
          " Be One to Give gave us a great opportunity to partner with their
          team to advance our sustainability goals. It is such an easy program
          to onboard and the simplicity of the app allows our organization to
          track our impact while reducing food waste from events. "
        </p>
      </div>

      <div className="container testimonial-container">
        <div className="testimonial-wrapper">
          <p className="testimonial-author">
            Kavelle Maharaj, Conference Services Manager
          </p>
          <img
            src={sheridanLogo}
            alt="Sheridan Conference Center logo"
            className="testimonial-client-logo"
          />
        </div>
        <div className="testimonial-wrapper">
          <div className="client-impact-wrapper">
            <img
              src={arrow}
              alt="Arrow pointing to the client's impact number"
              className="testimonial-arrow"
            />
            <p className="client-impact-number">1239 lbs</p>
          </div>
          <p className="client-impact-timeline">
            Fall/Spring semester impact @ 5 pickups per month
          </p>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
