import "../Banner/Banner.scss";

const Banner = ({ image }) => {
	return (
		<img
			src={image}
			alt="Page Banner"
			className="banner"
		/>
	);
};

export default Banner;
