import "../Information/Information.scss";

import { AiOutlineUser } from "react-icons/ai";
import { BiStopwatch } from "react-icons/bi";
import { BiPackage } from "react-icons/bi";
import { BiLineChart } from "react-icons/bi";
import appStoreBadge from "../../../images/app-store-badge.png";
import googlePlayBadge from "../../../images/google-play-badge.png";
import retailerAppFloating from "../../../images/retailer-app-floating.png";

function Information() {
  return (
    <div className="container pricing-information-container">
      <div className="information-section-right">
        <div className="container pricing-section">
          <h2 className="section-title-orange">Pricing</h2>
          <div className="pricing-package-container">
            <div className="pricing-package">
              <div className="package-header">
                <p className="package-type">
                  <b>Full Service Package</b>
                </p>
                <p className="package-price">
                  $1 per lb with our recyclable packaging
                </p>
              </div>
              <div className="package-info">
                <p>✔ Monthly billing or pay-as-you-go</p>
              </div>
            </div>
            <div className="pricing-package">
              <div className="package-header">
                <p>
                  <b>DIY Package</b>
                </p>
                <p className="package-price">
                  $.50 per lb for prepackaged food
                </p>
              </div>
              <div className="package-info">
                <p>✔ Monthly billing or pay-as-you-go</p>
              </div>
            </div>
          </div>
        </div>
        <div className="deliverables-wrapper">
          <h2 className="section-title-orange">Deliverables</h2>
          <div className="deliverables-list">
            <div className="deliverables-list-item">
              <AiOutlineUser className="check-icon" />
              <p className="deliverables-text">
                Unique login and access for your team.
              </p>
            </div>
            <div className="deliverables-list-item">
              <BiStopwatch className="check-icon" />
              <p className="deliverables-text">
                Same-day pickup <strong>guaranteed</strong> from 3pm - midnight.
              </p>
            </div>
            <div className="deliverables-list-item">
              <BiPackage className="check-icon" />
              <p className="deliverables-text">
                Package and labels to identify contents and food allergens.
              </p>
            </div>
            <div className="deliverables-list-item">
              <BiLineChart className="check-icon" />
              <p className="deliverables-text">Daily in-app impact reports.</p>
            </div>
          </div>
          <p>
            <em>Monthly subscriptions available ~ contact us to learn more.</em>
          </p>
        </div>
      </div>
      <div className="download-now-wrapper">
        <img
          src={retailerAppFloating}
          alt="Retailer App Floating Mockup"
          className="retailer-app-floating"
        />
        <div className="app-download-container">
          <a
            href="https://linktr.ee/b12give"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={appStoreBadge}
              alt="App Store Badge"
              className="app-download-badge"
            />
          </a>
          <a
            href="https://linktr.ee/b12give"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={googlePlayBadge}
              alt="Google Play Badge"
              className="app-download-badge"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Information;
