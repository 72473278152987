import { Link } from "react-router-dom";
import "./MissionStatements.scss";

import { GoGoal } from 'react-icons/go';
import { PiBinoculars } from 'react-icons/pi';
import { LiaFlag } from 'react-icons/lia';

function MissionStatements() {
	return (
		<div className="section-container">
            <div className="section-wrapper">
				<div className="card-wrapper">
                    <GoGoal className="card-icon"/>
					<div className="card-content">
						<h3 className="card-header">- mission -</h3>
						<p className="card-text">
							To close the gap between surplus food and food insecurity across
							Canada.
						</p>
					</div>
				</div>
				<div className="card-wrapper">
                    <PiBinoculars className="card-icon"/>
					<div className="card-content">
						<h3 className="card-header">- vision -</h3>
						<p className="card-text">
							To be the leading logistics partner for businesses along the
							supply chain. Surplus food delivery is just one pillar of our
							ultimate plan to build a more circular network of resource
							redistribution.
						</p>
					</div>
				</div>
				<div className="card-wrapper">
                    <LiaFlag className="card-icon"/>
					<div className="card-content">
						<h3 className="card-header">- goal -</h3>
						<p className="card-text">
							To redistribute 100% of avoidable food waste across Canada by
							2030.
						</p>
					</div>
				</div>
			</div>
			<Link
				to="/pricing"
				className="primary-button"
			>
				Learn more
			</Link>
		</div>
	);
}

export default MissionStatements;
