import Layout from "../../components/Layout";
import Information from "./Information/Information";
import SupportersGoals from "./SupportersGoals/SupportersGoals";

import "../PricingPage/PricingPage.scss";

function PricingPage() {
  return (
    <Layout>
      <Information />
      <SupportersGoals />
    </Layout>
  );
}

export default PricingPage;
