import { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "../Navbar/NavigationBar.scss";

import logo from "../../images/logo.png";
import { RxHamburgerMenu } from "react-icons/rx";

function NavigationBar() {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Navbar expand="lg" className="navbar-custom-bg" expanded={expanded}>
      <Container>
        <Navbar.Brand as={Link} to="/" onClick={handleLinkClick}>
          <img src={logo} alt="B12GIVE logo" className="navbar-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}>
          <RxHamburgerMenu className="toggle-custom-icon" />
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/our-story" onClick={handleLinkClick}>
              Our story
            </Nav.Link>
            <Nav.Link as={Link} to="/the-problem" onClick={handleLinkClick}>
              The Problem
            </Nav.Link>
            <Nav.Link as={Link} to="/pricing" onClick={handleLinkClick}>
              Pricing
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/gallery" onClick={handleLinkClick}>
              Gallery
            </Nav.Link> */}
            {/* <Nav.Link as={Link} to="/careers" onClick={handleLinkClick}>
              Careers
            </Nav.Link> */}
            <Nav.Link href="mailto:support@b12give.com">Contact</Nav.Link>
            <Nav.Link as={Link} to="/faq" onClick={handleLinkClick}>
              FAQ
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
